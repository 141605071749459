import styled from "@emotion/styled";
import { IonButton, IonCard, IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";

export const Button = styled(IonButton)`
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 24px;
  --padding-end: 24px;

  height: auto;
  font-size: 16px;
`;

export const Card = styled(IonCard)`
  box-shadow: 0px 2px 6px -3px #00000040;
  border: 1px solid #e0e0e080;
`;

export const Item = styled(IonItem)`
  --padding-start: 16px;
  --padding-end: 16px;
`;

export const Grid = styled(IonGrid)`
  padding: 0;
`;

export const Row = styled(IonRow)`
  padding: 0;
`;

const Col = styled(IonCol)<{ flex?: number }>`
  padding: 0;
  ${({ flex }) => (flex !== undefined ? `flex: ${flex};` : undefined)}
`;

export const CardTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-top: 16px;
`;

export const ContentCol = styled(Col)`
  flex: 1;
  margin-bottom: 12px;
`;

export const ScoreCol = styled(Col)`
  display: flex;
  align-items: center;
  width: auto;
  flex-grow: 0;
`;

export const Description = styled(IonText)`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const RatingScore = styled.span<{
  color: "success" | "warning" | "danger" | "dark" | "shift-priority" | "streak";
  length: number;
}>`
  font-size: ${({ length }) => `${length > 3 ? 48 * (2 / length) : 48}px;`}
  font-variant-numeric: tabular-nums;
  font-weight: 600;
  background-color: ${({ color }) => `var(--ion-color-${color})`};
  padding: 8px;
  color: white;
  border-radius: 100%;
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
