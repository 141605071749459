import { isDefined } from "@clipboard-health/util-ts";
import { IonList, IonPage } from "@ionic/react";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { useGetFacilitiesExclusions } from "@src/appV2/Facilities/api/useGetFacilitiesExclusions";
import { AppBarHeader, PageWithHeader } from "@src/appV2/lib";
import { STREAK_TRACKER_PATH } from "@src/appV2/Streaks";
import {
  useStreaksEnabledForHcp,
  useStreaksEnabledGlobally,
} from "@src/appV2/Streaks/api/featureFlagHooks";
import { useGetAgentActiveStreaks } from "@src/appV2/Streaks/api/useGetAgentActiveStreaks";
import {
  filterStreaksByDateRange,
  getCurrentActiveStreaks,
  getPayBoostActiveStreaks,
} from "@src/appV2/Streaks/utils";
import { OnboardingIncentiveBanner } from "@src/appV2/WorkerOnboarding/OnboardingIncentiveBanner";
import { MioStages } from "@src/appV2/WorkerOnboarding/types";
import { useDefinedAgent } from "@store/helperHooks";
import { startOfWeek, subWeeks } from "date-fns";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

import { usePreferredWorkplaceCount } from "./api/usePreferredWorkplaceCount";
import { CardLoader } from "./CardLoader";
import { pluralizeFacility, pluralizeFacilityWithThe } from "./lang";
import { logViewDetailsClicked } from "./logs";
import {
  Button,
  Card,
  CardTitle,
  ContentCol,
  Description,
  Grid,
  Item,
  RatingScore,
  Row,
  ScoreCol,
} from "./RatingsPage.styles";
import { getScoreSeverity } from "./utils";

export function RatingsPage() {
  const history = useHistory();
  const { userId = "" } = useDefinedAgent();

  const {
    isLoading: isAttendanceScoreLoading,
    data: attendanceScoreData,
    refetch: refetchAttendanceScore,
  } = useGetAttendanceScoreProfile(userId);
  const {
    isLoading: isPreferredWorkplaceLoading,
    data: preferredWorkplaceData,
    refetch: refetchPreferredWorkplace,
  } = usePreferredWorkplaceCount(userId, {
    meta: {
      userErrorMessage: "Something went wrong while loading your priority access facilities.",
    },
  });

  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const isStreaksOnForHcp = useStreaksEnabledForHcp();

  const {
    isLoading: isStreaksLoading,
    data: streaksResponse,
    refetch: refetchStreaks,
  } = useGetAgentActiveStreaks(
    { agentId: userId },
    { enabled: !isEmpty(userId) && isStreaksEnabledGlobally }
  );

  const handleAttendanceScoreClicked = () => {
    logViewDetailsClicked(userId, "attendance score");
    history.push(TabRouterPath.RATINGS_ATTENDANCE_SCORE);
  };

  const handlePriorityAccessClicked = () => {
    logViewDetailsClicked(userId, "priority access");
    history.push(TabRouterPath.RATINGS_PRIORITY_ACCESS);
  };

  const numberOfPriorityAccesses = preferredWorkplaceData?.workplaceCountByReason
    ? preferredWorkplaceData.workplaceCountByReason.RATING +
      preferredWorkplaceData.workplaceCountByReason.INTERNAL_CRITERIA +
      preferredWorkplaceData.workplaceCountByReason.FAVORITE
    : undefined;

  const payBoostActiveStreaks = getPayBoostActiveStreaks(streaksResponse?.data);
  const exclusionsQueries = useGetFacilitiesExclusions({
    facilityIds: payBoostActiveStreaks.map((streak) => streak.attributes.facilityId),
    agentId: userId,
  });

  const excludedFacilities = new Set(
    exclusionsQueries
      .flatMap((queryResult) => queryResult.data?.data)
      .map((exclusion) => exclusion?.facility.userId)
      .filter((id) => isDefined(id))
  );

  const streaksAtValidFacilities = payBoostActiveStreaks.filter((streak) => {
    return !excludedFacilities.has(streak.attributes.facilityId);
  });

  const currentPayBoostActiveStreaks = getCurrentActiveStreaks(streaksAtValidFacilities);

  const hasPayBoostActiveStreakInTheLast2Weeks =
    filterStreaksByDateRange({
      streaks: streaksAtValidFacilities,
      from: subWeeks(startOfWeek(new Date()), 2),
    }).length > 0;

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <>
            <OnboardingIncentiveBanner workerId={userId} stage={MioStages.FINAL} />
            <AppBarHeader title="Account Status" />
          </>
        }
        containerVariant="without-margin"
        containerMaxWidth="lg"
        onRefresh={() => {
          return Promise.all([
            refetchAttendanceScore(),
            refetchPreferredWorkplace(),
            isStreaksEnabledGlobally ? refetchStreaks() : Promise.resolve(),
          ]);
        }}
      >
        {isPreferredWorkplaceLoading ||
        isAttendanceScoreLoading ||
        (isStreaksEnabledGlobally && isStreaksLoading) ? (
          <CardLoader />
        ) : (
          <>
            {attendanceScoreData && (
              <Card>
                <Grid>
                  <Row>
                    <ContentCol>
                      <IonList lines="none">
                        <Item>
                          <CardTitle>Attendance Score</CardTitle>
                        </Item>
                        <Item>
                          <Description>
                            Your account’s attendance score is {attendanceScoreData.score}. Learn
                            more about how Attendance Score works.
                          </Description>
                        </Item>
                        <Item>
                          <Button
                            fill="outline"
                            shape="round"
                            color={getScoreSeverity(attendanceScoreData.score)}
                            data-testid="view-attendance-score-button"
                            onClick={handleAttendanceScoreClicked}
                          >
                            See details
                          </Button>
                        </Item>
                      </IonList>
                    </ContentCol>
                    <ScoreCol>
                      <RatingScore
                        color={getScoreSeverity(attendanceScoreData.score)}
                        data-testid="attendance-score"
                        length={attendanceScoreData.score.toString().length}
                      >
                        <span>{attendanceScoreData.score}</span>
                      </RatingScore>
                    </ScoreCol>
                  </Row>
                </Grid>
              </Card>
            )}
            {isStreaksEnabledGlobally &&
              (isStreaksOnForHcp || hasPayBoostActiveStreakInTheLast2Weeks) && (
                <Card>
                  <Grid>
                    <Row>
                      <ContentCol>
                        <IonList lines="none">
                          <Item>
                            <CardTitle>Streaks (Beta)</CardTitle>
                          </Item>
                          <Item>
                            <Description>
                              You have active streaks at{" "}
                              {pluralizeFacility(currentPayBoostActiveStreaks.length)}. Earn bonuses
                              at facilities where you have a streak.
                            </Description>
                          </Item>
                          <Item>
                            <Button
                              fill="outline"
                              shape="round"
                              color="streak"
                              size="default"
                              onClick={() => {
                                logViewDetailsClicked(userId, "streaks");
                                history.push(STREAK_TRACKER_PATH);
                              }}
                            >
                              See details
                            </Button>
                          </Item>
                        </IonList>
                      </ContentCol>
                      <ScoreCol>
                        <RatingScore color="streak" length={currentPayBoostActiveStreaks.length}>
                          <span>{currentPayBoostActiveStreaks.length}</span>
                        </RatingScore>
                      </ScoreCol>
                    </Row>
                  </Grid>
                </Card>
              )}
            {numberOfPriorityAccesses !== undefined && (
              <Card>
                <Grid>
                  <Row>
                    <ContentCol>
                      <IonList lines="none">
                        <Item>
                          <CardTitle>My Priority Access</CardTitle>
                        </Item>
                        <Item>
                          <Description>
                            You have Priority Access at{" "}
                            {pluralizeFacility(numberOfPriorityAccesses)}.{" "}
                            {numberOfPriorityAccesses === 0 ? (
                              "Learn more about how Priority Access works."
                            ) : (
                              <>
                                {" "}
                                View {pluralizeFacilityWithThe(numberOfPriorityAccesses)} and learn
                                more about how Priority Access works.
                              </>
                            )}
                          </Description>
                        </Item>
                        <Item>
                          <Button
                            fill="outline"
                            shape="round"
                            color="shift-priority"
                            size="default"
                            data-testid="view-priority-access-button"
                            onClick={handlePriorityAccessClicked}
                          >
                            See details
                          </Button>
                        </Item>
                      </IonList>
                    </ContentCol>
                    <ScoreCol>
                      <RatingScore
                        color="shift-priority"
                        data-testid="priority-access-workplaces-account"
                        length={numberOfPriorityAccesses.toString().length}
                      >
                        <span>{numberOfPriorityAccesses}</span>
                      </RatingScore>
                    </ScoreCol>
                  </Row>
                </Grid>
              </Card>
            )}
          </>
        )}
      </PageWithHeader>
    </IonPage>
  );
}
